import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Breadcrumbs from "../components/common/Breadcrumbs/breadcrumbs";

const Plans = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="banner">
        <div className="bannerContent">
          <h1>Plans</h1>
          <div className="imgContainer">
            {/* <img src={AboutUsBanner} /> */}
          </div>
        </div>
      </div>

      <div className="plansPageContainer">
        <div className="plansPageContent">
          <Breadcrumbs text="Plans" />
          <div className="toggleContainer">
            <p>Billed Monthly</p>
            <button
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <FontAwesomeIcon icon={toggle ? faToggleOn : faToggleOff} />
            </button>
            <p>Billed Yearly</p>
          </div>

          <div class="pricingContainer ">
            <div className="pricingCard card1 shadow">
              <div className="header">
                <p className="priceTitle">Professional Plan</p>
                <p className="priceSubheader">
                  For Individuals and Small Teams
                </p>
              </div>

              <div className="priceRowContainer">
                <div className="priceLine">
                  <p className="price">{`$${toggle ? 10 : 15}`}</p>
                  <p className="priceDetails">/per user per month</p>
                </div>

                <div className="pointsContainer">
                  <div className="points">
                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Up to 15 Jobs per Account</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Up to 10,000 Candidates</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Unlimited Hiring Managers</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Plus the Features below</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="priceBtnContainer">
                <button className="priceBtn">Start 14-day Free Trial</button>
              </div>
            </div>

            <div className="pricingCard card2 specialPricingCard shadow">
              <div className="header">
                <p className="priceTitle">Enterprise Plan</p>
                <p className="priceSubheader">For Industry Leaders</p>
              </div>

              <div className="priceRowContainer">
                <div className="priceLine">
                  <p className="price">{`$${toggle ? 30 : 35}`}</p>
                  <p className="priceDetails">/per user per month</p>
                </div>

                <div className="pointsContainer">
                  <div className="points">
                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Unlimited Jobs</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Unlimited Candidates</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Unlimited Hiring Managers</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Plus the Features below</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="priceBtnContainer">
                <button className="priceBtn">Start 14-day Free Trial</button>
              </div>
            </div>

            <div className="pricingCard card3 shadow">
              <div className="header">
                <p className="priceTitle">Custom Plan</p>
                <p className="priceSubheader">For Custom Integration</p>
              </div>

              <div className="priceRowContainer">
                <div className="priceLine">
                  <p className="price">On Demand</p>
                </div>

                <div className="pointsContainer">
                  <div className="points">
                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Everything in Enterprise Plan</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Custom Features</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Custom Integrations</p>
                    </div>

                    <div className="pricingRow">
                      <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                      <p>Plus the Features below</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="priceBtnContainer">
                <button className="priceBtn">Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
