import {
  faAngleRight,
  faDownload,
  faEye,
  faLocation,
  faMessage,
  faPhone,
  faSignOut,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import {
  getResumes,
  getEmployeeProfile,
  signout,
  BaseUrl,
  deleteResume,
  postEducation,
  getEducation,
  getExperience,
  postExperience,
  getSkill,
  postSkill,
} from "../apicalls";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/common/spinner";
import Resume from "../components/common/resume";
import { toast } from "react-toastify";
import EducationCard from "../components/common/ProfileComponents/educationCard";
import EducationForm from "../components/common/ProfileComponents/educationForm";
import ExperienceCard from "../components/common/ProfileComponents/experienceCard";
import ExperienceForm from "../components/common/ProfileComponents/experienceForm";
import SkillForm from "../components/common/ProfileComponents/skillForm";
import SkillCard from "../components/common/ProfileComponents/skillCard";
import Modal from "../components/common/Modal/modal";
import SubHeading1 from "../components/common/SubHeading1/subHeading1";
import ActionButton from "../components/common/ActionButton/actionButton";
import ScreenError from "../components/common/ScreenError/screenError";
import ScreenLoading from "../components/common/ScreenLoading/screenLoading";

const Profile = () => {
  const { dispatch, token, type } = useContext(AuthContext);
  let navigate = useNavigate();
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState({
    education_type: "",
    starting_year: "",
    completion_year: "",
    starting_month: "",
    completion_month: "",
    ongoing: "",
    institute: "",
    cgpa: "",
  });
  const [experience, setExperience] = useState({
    job_title: "",
    company_name: "",
    starting_year: "",
    completion_year: "",
    starting_month: "",
    completion_month: "",
  });
  const [skill, setSkill] = useState({
    description: "",
    skill_level: "",
    year_of_experience: "",
  });

  const {
    isError,
    isLoading: profileLoading,
    data: profile,
  } = useQuery({
    queryKey: ["Profile", token],
    queryFn: getEmployeeProfile,
  });

  const {
    isLoading: resumeLoading,
    data: resumes,
    refetch,
  } = useQuery({
    queryKey: ["EmployeeResumes", token],
    queryFn: getResumes,
    enabled: type === "EMPLOYEE",
  });

  const {
    isLoading: educationLoading,
    data: educationData,
    refetch: refetchEducation,
  } = useQuery({
    queryKey: ["Education", token],
    queryFn: getEducation,
    enabled: type === "EMPLOYEE",
  });

  const {
    isLoading: experienceLoading,
    data: experienceData,
    refetch: refetchExperience,
  } = useQuery({
    queryKey: ["Experience", token],
    queryFn: getExperience,
    enabled: type === "EMPLOYEE",
  });

  const {
    isLoading: skillLoading,
    data: skillData,
    refetch: refetchSkill,
  } = useQuery({
    queryKey: ["Skill", token],
    queryFn: getSkill,
  });

  const DeleteResume = async (id) => {
    const res = await deleteResume(token, id, toast);
    if (res === "SUCCESS") refetch();
  };

  const HandleEducationSubmit = async () => {
    setLoading(true);
    const res = await postEducation(token, education, toast, setLoading);
    if (res === "SUCCESS") {
      setModal(null);
      refetchEducation();
    }
  };

  const HandleExperienceSubmit = async () => {
    setLoading(true);
    const res = await postExperience(token, experience, toast, setLoading);
    if (res === "SUCCESS") {
      setModal(null);
      refetchExperience();
    }
  };

  const HandleSkillSubmit = async () => {
    setLoading(true);
    const res = await postSkill(token, skill, toast, setLoading);
    if (res === "SUCCESS") {
      setModal(null);
      refetchSkill();
    }
  };

  if (
    type === "EMPLOYEE" &&
    (profileLoading ||
      skillLoading ||
      experienceLoading ||
      educationLoading ||
      resumeLoading)
  )
    return <ScreenLoading />;

  if (type === "EMPLOYER" && profileLoading) return <ScreenLoading />;

  if (isError) return <ScreenError />;

  return (
    <div className="profileContainer">
      <div className="profileContent">
        <div className="profileHeader">
          <div>
            <h1>{`${profile.first_name} ${profile.last_name}`}</h1>
          </div>
          <button
            className="signOutBtn"
            onClick={() => {
              signout(dispatch);
              navigate("/");
            }}
          >
            <FontAwesomeIcon icon={faSignOut} />
          </button>
        </div>

        <Link to="/editprofile">
          <button className="editContainer shadow">
            <FontAwesomeIcon icon={faMessage} />
            <p>{profile.email}</p>
            <FontAwesomeIcon icon={faPhone} />
            <p>
              {profile.contact_number
                ? profile.contact_number
                : "Contact number not added"}
            </p>
            <FontAwesomeIcon icon={faLocation} />
            <p>
              {profile.city && profile.state && profile.country
                ? `${profile.city}, ${profile.state}, ${profile.country}`
                : !profile.city && profile.state && profile.country
                ? `${profile.state}, ${profile.country}`
                : !profile.city && !profile.state && profile.country
                ? `${profile.country}`
                : ""}
            </p>

            <div className="rightIcon">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </button>
        </Link>

        {type === "EMPLOYEE" ? (
          <>
            <div className="educationContainer">
              <SubHeading1 text="Education" />
              {educationLoading ? (
                <Spinner />
              ) : !educationData?.length ? (
                <h4 className="warningText">No education added</h4>
              ) : (
                <div className="experiencesCardContainer">
                  {educationData?.map((e) => {
                    return (
                      <EducationCard
                        data={e}
                        refetchEducation={refetchEducation}
                      />
                    );
                  })}
                </div>
              )}

              <ActionButton
                text="Add Education"
                handleClick={() => {
                  setModal(1);
                }}
              />
            </div>

            <div className="educationContainer">
              <SubHeading1 text="Experience" />
              {experienceLoading ? (
                <Spinner />
              ) : !experienceData?.length ? (
                <h4 className="warningText">No experience added</h4>
              ) : (
                <div className="experiencesCardContainer">
                  {experienceData?.map((e) => {
                    return (
                      <ExperienceCard
                        data={e}
                        refetchExperience={refetchExperience}
                      />
                    );
                  })}
                </div>
              )}

              <ActionButton
                text="Add Experience"
                handleClick={() => {
                  setModal(2);
                }}
              />
            </div>

            <div className="educationContainer">
              <SubHeading1 text="Skill" />
              {skillLoading ? (
                <Spinner />
              ) : !skillData?.length ? (
                <h4 className="warningText">No skills added</h4>
              ) : (
                <div className="skillsCardContainer">
                  {skillData?.map((e) => {
                    return <SkillCard data={e} refetchSkill={refetchSkill} />;
                  })}
                </div>
              )}

              <ActionButton
                text="Add Skill"
                handleClick={() => {
                  setModal(3);
                }}
              />
            </div>

            <div className="resumeContainer">
              <SubHeading1 text="Resumes" />
              {resumeLoading ? <Spinner /> : null}
              {!resumes?.length ? (
                <h4 className="warningText" style={{ paddingTop: 15 }}>
                  No resumes added
                </h4>
              ) : null}
              {resumes.map((e, index) => {
                return (
                  <Resume
                    index={index}
                    e={e}
                    deleteFunc={() => {
                      DeleteResume(e.id);
                    }}
                  />
                );
              })}
              <div style={{ marginTop: 20 }}>
                <Link to="/uploadresume">
                  <ActionButton text="Upload Resume" handleClick={() => {}} />
                </Link>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <Modal modal={modal} setModal={setModal}>
        {modal === 1 ? (
          <div className="parsedContainer">
            <div className="header">
              <h3>Add Education</h3>
              <button
                onClick={() => {
                  setModal(null);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>

            <EducationForm
              education={education}
              setEducation={setEducation}
              handleSubmit={() => {
                HandleEducationSubmit();
              }}
              isEdit={false}
              loading={loading}
            />
          </div>
        ) : modal === 2 ? (
          <div className="parsedContainer">
            <div className="header">
              <h3>Add Experience</h3>

              <button
                onClick={() => {
                  setModal(null);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>

            <ExperienceForm
              experience={experience}
              setExperience={setExperience}
              handleSubmit={() => {
                HandleExperienceSubmit();
              }}
              isEdit={false}
              loading={loading}
            />
          </div>
        ) : (
          <div className="parsedContainer">
            <div className="header">
              <h3>Add Skill</h3>

              <button
                onClick={() => {
                  setModal(null);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>

            <SkillForm
              skill={skill}
              setSkill={setSkill}
              handleSubmit={() => {
                HandleSkillSubmit();
              }}
              isEdit={false}
              loading={loading}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Profile;
