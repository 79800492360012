import {
  faMinus,
  faPlus,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getCandidateListSearch } from "../../apicalls";
import Spinner from "../../components/common/spinner";
import SubHeading1 from "../../components/common/SubHeading1/subHeading1";
import ResumeSearchCard from "../../components/common/ResumeSearchCard/resumeSearchCard";
import CountryStateCity from "../../countries+states+cities.json";
import Searchbar from "../../components/common/Searchbar/searchbar";
import Heading1 from "../../components/common/Heading1/heading1";

const filter = [
  {
    name: "Skill Level",
    objName: "skill_level",
    options: [
      { name: "Beginner", value: "beginner" },
      { name: "Intermediate", value: "intermediate" },
      { name: "Expert", value: "expert" },
    ],
  },
];

const CandidateSearch = () => {
  const [filterFields, setFilterFields] = useState({});
  const [locationFields, setLocationFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [countryIndex, setCountryIndex] = useState(null);
  const [stateIndex, setStateIndex] = useState(null);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filters, setFilters] = useState([...filter]);
  const [isExpand, setIsExpand] = useState({
    skill_level: false,
  });

  const CheckFilterPresent = (filterArray, searchTerm) => {
    if (!filterArray || !filterArray.length) return false;

    for (let i = 0; i < filterArray.length; i++) {
      if (filterArray[i].name === searchTerm) return true;
    }
    return false;
  };

  useEffect(() => {
    const func = () => {
      const timer = setTimeout(async () => {
        let str = "";
        setResult([]);
        if (searchTerm) {
          str = str + `search=${searchTerm}&`;
        }
        if (Object.keys(filterFields).length) {
          let keys = Object.keys(filterFields);
          keys.forEach((key) => {
            let str2 = "";
            filterFields[key].forEach((e) => {
              str2 = str2 + `${e.value},`;
            });
            if (str2) {
              str = str + `${key}=${str2.substring(0, str2.length - 1)}&`;
            }
          });
        }
        if (Object.keys(locationFields).length) {
          let keys = Object.keys(locationFields);
          keys.forEach((key) => {
            str = str + `${key}=${locationFields[key]}&`;
          });
        }
        str = str.substring(0, str.length - 1);

        if (str) {
          setIsLoading(true);
          setIsError(false);
          console.log(str);
          const res = await getCandidateListSearch(
            str,
            setIsLoading,
            setIsError
          );
          setResult(res);
        }
      }, 500);
      return () => clearTimeout(timer);
    };
    const triggerCall = setTimeout(() => {
      if (
        Object.keys(filterFields).length ||
        Object.keys(locationFields).length ||
        searchTerm
      )
        func();
    }, 500);
    return () => clearTimeout(triggerCall);
  }, [filterFields, locationFields, searchTerm]);

  useEffect(() => {
    if (countryIndex) {
      let obj = locationFields;
      delete obj["state"];
      delete obj["city"];
      setLocationFields({ ...obj });
      setStateIndex(null);
    }
  }, [countryIndex]);

  useEffect(() => {
    let obj = locationFields;
    delete obj["city"];
    setLocationFields({ ...obj });
  }, [stateIndex]);

  return (
    <>
      <div className="dashboardHeader">
        <Heading1 text={"Search Resume"} />
      </div>

      <div className="candidateSearchContainer">
        <div className="searchContainer">
          <Searchbar
            searchterm={searchTerm}
            setSearchterm={setSearchTerm}
            placeholder={"Search..."}
            handleSubmit={() => {}}
          />
        </div>

        <div className="candidateSearchContent">
          <div className="filterContainer">
            {/* <div className="widget-boxed padd-bot-0 mar-bot-0">
            <div
              className="widget-boxed-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>Search</h4>
            </div>

            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div> */}

            {filters.map((filter) => {
              return (
                <div className="widget-boxed padd-bot-0 mar-bot-0">
                  <div
                    className="widget-boxed-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>{filter.name}</h4>

                    <button
                      onClick={() => {
                        let obj = { ...isExpand };
                        obj[filter.objName] = !obj[filter.objName];

                        setIsExpand({ ...obj });
                      }}
                      className="expandBtn"
                    >
                      <FontAwesomeIcon
                        icon={isExpand[filter.objName] ? faMinus : faPlus}
                      />
                    </button>
                  </div>

                  {isExpand[`${filter.objName}`] ? (
                    <div className="widget-boxed-body">
                      <div className="side-list no-border">
                        <ul>
                          {filter.options.map((e) => {
                            return (
                              <li
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  if (
                                    !filterFields[filter.objName] ||
                                    !CheckFilterPresent(
                                      filterFields[filter.objName],
                                      e.name
                                    )
                                  )
                                    setFilterFields({
                                      ...filterFields,
                                      [filter.objName]: filterFields[
                                        filter.objName
                                      ]
                                        ? [...filterFields[filter.objName], e]
                                        : [e],
                                    });
                                  else {
                                    let filteredArray = filterFields[
                                      filter.objName
                                    ].filter(function (i) {
                                      return i.name !== e.name;
                                    });

                                    if (filteredArray.length === 0) {
                                      let obj = filterFields;
                                      delete obj[filter.objName];

                                      setFilterFields({ ...obj });
                                    } else
                                      setFilterFields({
                                        ...filterFields,
                                        [filter.objName]: [...filteredArray],
                                      });
                                  }
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    CheckFilterPresent(
                                      filterFields[filter.objName],
                                      e.name
                                    )
                                      ? faSquareCheck
                                      : faSquare
                                  }
                                  style={{
                                    fontSize: 16,
                                    marginRight: 8,
                                    color: "#2557a7",
                                  }}
                                />
                                <p>{e.name}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}

            <div className="widget-boxed padd-bot-0 mar-bot-0">
              <div
                className="widget-boxed-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4>Country</h4>
              </div>

              <select
                type="text"
                class="form-control"
                placeholder="Search..."
                value={
                  locationFields.country
                    ? `${locationFields.country}+${countryIndex}`
                    : ""
                }
                onChange={(e) => {
                  setLocationFields({
                    ...locationFields,
                    country: e.target.value.substring(
                      0,
                      e.target.value.indexOf("+")
                    ),
                  });
                  setCountryIndex(
                    e.target.value.substring(e.target.value.indexOf("+") + 1)
                  );
                }}
              >
                <option disabled selected value="">
                  -- select a country --
                </option>
                {CountryStateCity?.map((e, index) => {
                  return <option value={`${e.name}+${index}`}>{e.name}</option>;
                })}
              </select>
            </div>

            {countryIndex ? (
              <div className="widget-boxed padd-bot-0 mar-bot-0">
                <div
                  className="widget-boxed-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4>State</h4>
                </div>

                <select
                  className="form-control"
                  required
                  value={
                    locationFields.state
                      ? `${locationFields.state}+${stateIndex}`
                      : ""
                  }
                  onChange={(e) => {
                    setLocationFields({
                      ...locationFields,
                      state: e.target.value.substring(
                        0,
                        e.target.value.indexOf("+")
                      ),
                    });
                    setStateIndex(
                      e.target.value.substring(e.target.value.indexOf("+") + 1)
                    );
                  }}
                >
                  <option disabled selected value="">
                    -- select a state --
                  </option>
                  {CountryStateCity[countryIndex]?.states?.map((e, index) => {
                    return (
                      <option value={`${e.name}+${index}`}>{e.name}</option>
                    );
                  })}
                </select>
              </div>
            ) : null}

            {stateIndex ? (
              <div className="widget-boxed padd-bot-0 mar-bot-0">
                <div
                  className="widget-boxed-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4>City</h4>
                </div>
                <select
                  className="form-control"
                  required
                  value={locationFields.city || ""}
                  onChange={(e) => {
                    setLocationFields({
                      ...locationFields,
                      city: e.target.value,
                    });
                  }}
                >
                  <option disabled selected value="">
                    -- select a city --
                  </option>
                  {CountryStateCity[countryIndex]?.states[
                    stateIndex
                  ]?.cities?.map((e) => {
                    return <option value={e.name}>{e.name}</option>;
                  })}
                </select>
              </div>
            ) : null}
          </div>

          {isLoading ? (
            <Spinner />
          ) : !result.length ? (
            <SubHeading1 text={"No result found"} />
          ) : (
            <div className="mainContent">
              {result.map((e) => {
                return <ResumeSearchCard data={e} />;
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CandidateSearch;
